// //引入ajax文件
import request from "../static/js/ajax";
// 获取首页数据
export default {
    //获取验证码图片
    getCode(query) {
        return request({
            url: "code",
            method: "get",
            query: query
        });
    },

    // 验证验证码
    verificationCode(query) {
        return request({
            url: "code",
            method: "post",
            data: query
        });
    },

    // 登录
    login(query) {
        return request({
            url: "login",
            method: "post",
            data: query,
        });
    },

    //退出
    logOut(query) {
        return request({
            url: "login/logout",
            method: "get",
            query: query,
        });
    },
   

    //订单列表
    getOrderList(query) {
        return request({
            url: 'api/finance/v1/order/list' ,
            method: "get",
            query: query,
        });
    },
    // 订单详情
    getOrderInfo(query) {
        return request({
            url: 'api/finance/v1/order/list/' + query.id ,
            method: "get",
            query: query,
        });
    },
     // 确认收款
     confirmPayment(query) {
        return request({
            url: 'api/finance/v1/order/confirm_pay',
            method: "post",
            data: query,
        });
    },
    // 第一次分账
    dispose(query) {
        return request({
            url: 'api/finance/v1/order/dispose',
            method: "post",
            data: query,
        });
    },
    // 第二次分账
    splitAmount(query) {
        return request({
            url: 'api/finance/v1/order/split_amount',
            method: "post",
            data: query,
        });
    },
    // 同意退款
    bankRefund(query) {
        return request({
            url: 'api/finance/v1/order/bank_refund',
            method: "post",
            data: query,
        });
    },
    // 订单日志列表
    getViewLogs(query) {
        return request({
            url: 'api/finance/v1/order/logs' ,
            method: "get",
            query: query,
        });
    },
    // 添加日志
    addLogs(query) {
        return request({
            url: 'api/finance/v1/order/logs',
            method: "post",
            data: query,
        });
    },
     // 销售统计列表/导出
     getstatisticsProduct(query) {
        return request({
            url: 'api/finance/v1/statistics/product' ,
            method: "get",
            query: query,
        });
    },
    // 分账统计/导出
    divisionAccStatist(query) {
        return request({
            url: 'api/finance/v1/statistics/ledger',
            method: "get",
            query: query,
        });
    },
    // 统计报表列表/导出
    orderStatist(query) {
        return request({
            url: 'api/finance/v1/statistics/order',
            method: "get",
            query: query,
        });
    },
    // 个人资料详情
    getPersonalInfo(query) {
        return request({
            url: 'api/finance/v1/user/info',
            method: "get",
            query: query,
        });
    },
    //修改个人资料
    modifyPersonal(query) {
        return request({
            url: "api/finance/v1/user/modify",
            method: "put",
            data: query,
        });
    },
    // 发送短信验证码
    sendSms(query) {
        return request({
            url: 'api/finance/v1/user/sms',
            method: "get",
            query: query,
        });
    },
    //修改手机号
    modifyMobile(query) {
        return request({
            url: "api/finance/v1/user/mobile",
            method: "put",
            data: query,
        });
    },
    //修改密码
    modifyPassword(query) {
        return request({
            url: "api/finance/v1/user/password",
            method: "put",
            data: query,
        });
    },
    // 获取首页
	getIndex(query) {
		return request({
			url: "api/finance/v1/index/index",
			method: "get",
            query: query,
		});
	},
     // 查询物流
	getlogistics(query) {
		return request({
			url: "express/query",
			method: "get",
            query: query,
		});
	},
     // 导出订单
     exportOrder(query) {
		return request({
			url: "api/finance/v1/order/export",
			method: "get",
            query: query,
		});
	},
	// 弹窗列表
	 getpopupList(query) {
		return request({
			url: "api/finance/v1/Statistics/getorder",
			method: "get",
	        query: query,
		});
	},
	// 导出发票
    exportInvoice(query) {
		return request({
			url: "api/finance/v1/Order/invoice",
			method: "get",
	        query: query,
		});
	},
    // 开票列表
    getInvoicingList(query) {
		return request({
			url: "api/finance/v1/order/invoic",
			method: "get",
	        query: query,
		});
	},
    // 确认开票
    confirmInvoicing(query) {
		return request({
			url: "api/finance/v1/order/invoic",
			method: "put",
	        data: query,
		});
	},

    getKey(query) {
		return request({
			url: "api/finance/v1/accesskey",
			method: "get",
	        // data: query,
		});
	},
};