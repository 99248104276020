import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false, //用于开启加载
        adminInfo: {},
        title: '',
        collapse:null,
    },
    getters: {},
    mutations: {
        // 更新加载
        upLoading(state, bool) {
            state.loading = bool
        },

        // 登录信息
        upAdminInfo(state, data) {
            state.adminInfo = data
        },
        // 页面标题
        upTitle(state, str) {
            state.title = str
        },
        upCollapse(state, bool) {
            state.collapse = bool
        },
    },
    actions: {},
    modules: {}
})