import axios from 'axios';
import QueryString from 'qs';
import router from '../../router/index' // 导入路由文件
import store from '@/store/index.js';

import {
    MessageBox,
    Loading,
    Message
} from 'element-ui'
import {
    configure
} from "./config";

import {
    getCookie
} from "../../static/js/cookie";

const service = axios.create({
    baseURL: configure.baseURL,
    timeout: 1500000,
    headers: {
        Client: 2
    }
});

// loading对象
let loadingInstance;

// 请求合并只出现一次loading
// 当前正在请求的数量
let loadingRequestCount = 0;
// 添加请求拦截器
service.interceptors.request.use(
    function (con) {
        // token 存储类型
        let token = null
        if (configure.tokenType == 1) {
            token = localStorage.getItem('token')
        } else if (configure.tokenType == 2) {
            token = sessionStorage.getItem('token')
        } else {
            token = getCookie('token')
        }
        con.headers["Content-Type"] = "application/x-www-form-urlencoded"
        //get方式请求 
        if (con.method == 'get') {
            if (con.url !== 'api/finance/v1/accesskey') {
                con.query.acid = 3
                con.url += "?" + QueryString.stringify(con.query)
            }


        } else {
            // post方式
            con.url += "?acid=3"
            con.data = QueryString.stringify(con.data);
        }
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            // config.headers.authorization = token  //请求头加上token
            con.headers["Authorization"] = token
        }
        if (store.state.loading) {
            showLoading()
        }
        return con;
    },
    function (err) {
        loadingInstance.close()
        // 请求失败的处理
        return Promise.reject(err);
    }
);


service.interceptors.response.use(
    response => {
        hideLoading() //隐藏加载动画
        store.commit('upLoading', false)
        return response.data
    },
    error => {
        hideLoading() //隐藏加载动画
        store.commit('upLoading', false)
        // //等于401 代表登录过期超时
        try {
            if (error.response.status == 402) {
                if (loadingRequestCount == 0) {

                    MessageBox.confirm('当前登录已过期，是否前去重新登录?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // 到登录页
                        router.push('/Login')

                    }).catch(() => {
                        // 首页
                        router.push('/Login')

                    });
                    switch (configure.tokenType) {
                        case 1: //localStorage
                            localStorage.removeItem('token')
                            break;
                        case 2: //sessionStorage
                            sessionStorage.removeItem('token')
                            break;
                        case 3: //cookie 
                            delCookie('token')
                            break;
                    }
                }
            }
        } catch (err) {

        }
        console.log(error);
        Message.error(error.message)
        // 当响应异常时做一些处理
        return Promise.reject(error);
    }
)


// 显示loading的函数 并且记录请求次数 ++
const showLoading = (target) => {
    if (loadingRequestCount == 0) {
        loadingInstance = Loading.service({
            lock: true,
            text: '努力加载中...',
            target: target
        });
    }
    loadingRequestCount++;
}

// 隐藏loading的函数，并且记录请求次数
const hideLoading = () => {
    if (loadingRequestCount <= 0) return;
    loadingRequestCount--;
    loadingInstance.close();
}

export default service;