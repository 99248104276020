module.exports = {
    data() {
        return {
            Img: 'https://img1.baidu.com/it/u=592570905,1313515675&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656090000&t=57db7bca9b84ff3c628ad697b33e7193'
        }
    },
    methods: {
        // 默认图片
        defaultImg() {
            return "https://img1.baidu.com/it/u=592570905,1313515675&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656090000&t=57db7bca9b84ff3c628ad697b33e7193";
        },
        // 转为时间搓
        dataChange(d) {
            var d = new Date(d);
            let time =
                d.getFullYear() +
                "-" +
                this.twoDate(d.getMonth() + 1) +
                "-" +
                this.twoDate(d.getDate()) +
                " " +
                this.twoDate(d.getHours()) +
                ":" +
                this.twoDate(d.getMinutes()) +
                ":" +
                this.twoDate(d.getSeconds());
            return time;
        },

        // 补0
        twoDate(d) {
            return d < 10 ? "0" + d : d;
        },
    }
}