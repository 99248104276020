import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'homeView',
        component: () =>
            import ('../views/HomeView/HomeView.vue'),
        children: [{
                path: '/Home',
                name: 'home',
                component: () =>
                    import ('../views/Home.vue'),
                meta: {
                    title: "个人中心",
                    keepAlive: false,
                },
            }, {
                path: '/404',
                name: '404',
                component: () =>
                    import ('../views/error/404.vue')
            }, {
                path: '/OrderList',
                name: 'orderList',
                component: () =>
                    import ('../views/orderManage/orderList.vue'),
                meta: {
                    title: "订单列表",
                    keepAlive: false,
                },
            },
            {
                path: '/OrderInfo',
                name: 'orderInfo',
                component: () =>
                    import ('../views/orderManage/orderInfo.vue'),
                meta: {
                    title: "订单详情",
                    keepAlive: false,
                },
            },
            {
                path: '/SalesStatistics',
                name: 'salesStatistics',
                component: () =>
                    import ('../views/managementStatistics/salesStatistics.vue'),
                meta: {
                    title: "销售统计",
                    keepAlive: false,
                },
            },
            {
                path: '/StatisticalReport',
                name: 'statisticalReport',
                component: () =>
                    import ('../views/managementStatistics/statisticalReport.vue'),
                meta: {
                    title: "统计报表",
                    keepAlive: false,
                },
            },
            {
                path: '/DivisionAccountStatistics',
                name: 'divisionAccountStatistics',
                component: () =>
                    import ('../views/managementStatistics/divisionAccountStatistics.vue'),
                meta: {
                    title: "分账统计",
                    keepAlive: false,
                },
            },
            {
                path: '/PersonalData',
                name: 'personalData',
                component: () =>
                    import ('../views/personalCenter/personalData.vue'),
                meta: {
                    title: "个人资料",
                    keepAlive: false,
                },
            },
            {
                path: '/ModifyPhone',
                name: 'modifyPhone',
                component: () =>
                    import ('../views/personalCenter/modifyPhone.vue'),
                meta: {
                    title: "修改手机",
                    keepAlive: false,
                },
            },
            {
                path: '/PasswordSetting',
                name: 'passwordSetting',
                component: () =>
                    import ('../views/personalCenter/passwordSetting.vue'),
                meta: {
                    title: "密码设置",
                    keepAlive: false,
                },
            },
            {
                path: '/InvoicingList',
                name: 'invoicingList',
                component: () =>
                    import ('@/views/invoicingList/invoicingList.vue'),
                meta: {
                    title: "开票列表",
                    keepAlive: false,
                },
            },
        ]
    },
    {
        path: '/Login',
        name: 'login',
        component: () =>
            import ('../views/Login.vue'),
        meta: {
            title: "登录",
            keepAlive: false,
        },
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    }
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
export default router